import React from 'react'
import styled from 'styled-components'
import { palette, theme } from 'styled-tools'
import { Link } from 'gatsby'

const Button = styled.button`
  display: block;
  width: max-content;
  color: ${palette('white')};
  font-weight: 500;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 2.25;
  text-decoration: none;
  transition: ${theme('transition.base')};

  &:not([disabled]):focus,
  &:not([disabled]):hover {
    cursor: pointer;
  }
`

export const ContainedButton = styled((props) => <Button {...props} />)`
  padding: 0.75rem 2rem;
  background-color: ${palette('black', 1)};
  border: none;

  &:not([disabled]):focus,
  &:not([disabled]):hover {
    background-color: ${palette('green')};
  }
`

export const OutlinedButton = styled((props) => <Button {...props} />)`
  padding: 4px 2rem;
  color: ${palette('black')};
  background-color: transparent;
  border: 1px solid ${palette('black')};

  &:not([disabled]):focus,
  &:not([disabled]):hover {
    color: ${palette('green')};
    border-color: ${palette('green')};
  }
`
const StyledGatsbyLink = Button.withComponent(Link)
const StyledLink = Button.withComponent('a')

export const ContainedGatsbyLink = ContainedButton.withComponent(StyledGatsbyLink)
export const OutlinedGatsbyLink = OutlinedButton.withComponent(StyledGatsbyLink)
export const ContainedLink = ContainedButton.withComponent(StyledLink)
export const OutlinedLink = OutlinedButton.withComponent(StyledLink)
