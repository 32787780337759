import React from 'react'
import PropTypes from 'prop-types'

import {
  ContainedButton,
  ContainedGatsbyLink,
  ContainedLink,
  OutlinedButton,
  OutlinedLink,
  OutlinedGatsbyLink,
} from './styled'

const buttonTypes = {
  full: 'full',
  outline: 'outline',
}

const buttons = {
  gatsby: {
    [buttonTypes.full]: ContainedGatsbyLink,
    [buttonTypes.outline]: OutlinedGatsbyLink,
  },
  button: {
    [buttonTypes.full]: ContainedButton,
    [buttonTypes.outline]: OutlinedButton,
  },
  a: {
    [buttonTypes.full]: ContainedLink,
    [buttonTypes.outline]: OutlinedLink,
  },
}

const getButton = (containType, buttonType, props, children) =>
  React.createElement(buttons[buttonType][containType], props, children)

const defaultOnClick = () => {}

const Button = ({
  type, to, href, onClick, children, ...props
}) => {
  if (to) {
    return getButton(type, 'gatsby', { to, ...props }, children)
  }
  if (defaultOnClick !== onClick || !href) {
    return getButton(type, 'button', { onClick, ...props }, children)
  }
  return getButton(
    type,
    'a',
    {
      target: '_blank',
      rel: 'noopener noreferrer',
      href,
      ...props,
    },
    children
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['full', 'outline']),
  to: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
}

Button.defaultProps = {
  type: buttonTypes.full,
  to: null,
  href: null,
  onClick: defaultOnClick,
}

export default Button
